import { IconMinus, IconPlus, IconTrash } from '@tabler/icons-react';
import { FormikProps } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ATMButton } from 'src/components/atoms/ATMButton/ATMButton';
import ATMSelect from 'src/components/atoms/FormElements/ATMSelect/ATMSelect';
import { useFetchData } from 'src/hooks/useFetchData';
import { useGetCustomersQuery } from 'src/modules/Customer/service/CustomerServices';
import { useGetOutletsQuery } from 'src/modules/Outlet/service/OutletServices';
import { AppDispatch, RootState } from 'src/store';
import { CURRENCY } from 'src/utils/constants';
import { showToast } from 'src/utils/showToaster';
import { useGetDraftByIdMutation } from '../service/POSServices';
import {
  setIsOpenAddDialog,
  setIsOpenCustomerDialog,
  setIsOpenDraftListDialog,
} from '../slice/CartSlice';
import DraftList from './DraftList';
import PaymentFormWrapper from './PaymentFormWrapper';

type AmountSummaryCardProps = {
  items: any[];
  customer: any;
};

export const calculatedAmounts = (items: any) => {
  let taxes: {
    taxType: string;
    taxAmount: number;
    taxPercent: number;
  }[] = [];
  let totalAmount = 0;

  items?.forEach((item: any) => {
    const matchedIndex = taxes?.findIndex(
      (el: any) => el?.taxType === item?.taxType,
    );
    if (matchedIndex > -1) {
      taxes[matchedIndex].taxAmount +=
        (item?.quantity * item?.sellingPrice * item?.taxPercent || 0) / 100;
    } else {
      taxes?.push({
        taxType: item?.taxType,
        taxPercent: item?.taxPercent,
        taxAmount:
          (item?.quantity * item?.sellingPrice * item?.taxPercent || 0) / 100,
      });
    }

    totalAmount +=
      Number(item?.sellingPrice) *
      Number(item?.quantity) *
      ((100 + (Number(item?.taxPercent) || 0)) / 100);
  });

  return {
    taxes,
    totalAmount: totalAmount?.toFixed(2),
  };
};

const AmountSummaryCard = ({ items, customer }: AmountSummaryCardProps) => {
  const dispatch = useDispatch<AppDispatch>();
  return (
    <div className="flex flex-col gap-2 space-y-1">
      <div>
        <div className="">
          {calculatedAmounts(items)?.taxes?.map((tax: any, index) => (
            <div key={index} className="flex gap-2 p-4 py-1">
              <div className="flex-1 text-xs truncate text-slate-500">
                {tax?.taxType && tax?.taxPercent ? (
                  <div>
                    {' '}
                    {tax?.taxType} @ {tax?.taxPercent}%
                  </div>
                ) : null}
              </div>
              {tax?.taxAmount ? (
                <div className="min-w-[60px] text-sm  text-slate-600 text-right">
                  {CURRENCY} {tax?.taxAmount.toFixed(2)}
                </div>
              ) : null}
            </div>
          ))}
        </div>

        <div className="flex gap-2 px-4 py-1 bg-yellow-100">
          <div className="flex-1 text-base font-medium ">Sub Total</div>
          <div className="min-w-[60px] text-base font-medium text-slate-800 text-right">
            {CURRENCY} {calculatedAmounts(items)?.totalAmount}
          </div>
        </div>
      </div>
      <div className="px-4 ">
        <ATMButton
          onClick={() => dispatch(setIsOpenAddDialog(true))}
          children="Proceed Ahead"
          disabled={!customer}
        />
      </div>
    </div>
  );
};

type Props = {
  cartItems: any[];
  onRemove: (itemId: string) => void;
  onQuantityChange: (itemId: string, type: 'INCREMENT' | 'DECREMENT') => void;
  formikProps: FormikProps<any>;
};

const CartSummarySection = ({
  cartItems,
  onRemove,
  onQuantityChange,
  formikProps,
}: Props) => {
  const { values, setFieldValue } = formikProps;

  const { userData, outlet } = useSelector((state: RootState) => state.auth);
  const { isOpenDraftListDialog, isOpenCustomerDialog } = useSelector(
    (state: RootState) => state.cart,
  );
  const navigate = useNavigate();
  const { data: outletData, isLoading: outletDataLoading }: any = useFetchData(
    useGetOutletsQuery,
    {
      body: { isPaginationRequired: false },
    },
  );
  const { data: customerData, isLoading: customerLoading } = useFetchData(
    useGetCustomersQuery,
    {
      body: {
        isPaginationRequired: false,
        filterBy: JSON.stringify([
          {
            fieldName: 'isActive',
            value: true,
          },
        ]),
      },
    },
  );

  const [getDraftData] = useGetDraftByIdMutation();

  const { isOpenAddDialog } = useSelector((state: RootState) => state?.cart);
  const dispatch = useDispatch<AppDispatch>();
  const handleGetDraftData = (draftId: string) => {
    getDraftData(draftId).then((res: any) => {
      if (res?.error) {
        showToast('error', res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          setFieldValue('customer', { _id: res?.data?.data?.customerId });
          setFieldValue(
            'items',
            res?.data?.data?.items?.map((item: any) => {
              return {
                _id: item?.itemId,
                // itemCode: item?.itemId,
                itemName: item?.itemName,
                // itemUrl: item?.itemId,
                mrp: item?.mrp,
                // purchasePrice: item?.itemId,
                sellingPrice: item?.sellingPrice,
                quantity: item?.quantity,
                type: item?.itemType,
                taxId: item?.taxId,
                taxPercent: item?.taxPercent,
                taxType: item?.taxType,
              };
            }),
          );
          setFieldValue('couponCode', res?.data?.data?.couponCode);
          setFieldValue('shippingCharges', res?.data?.data?.shippingCharges);
          setFieldValue(
            'amountReceived',
            res?.data?.data?.amountReceived?.map((el: any) => ({
              paymentModeId: { _id: el?.paymentModeId },
              amount: el?.amount?.toFixed(2),
            })),
          );
          setFieldValue('giftCardCode', res?.data?.data?.giftCardCode);
          setFieldValue('useLoyaltyPoints', res?.data?.data?.useLoyaltyPoints);
          setFieldValue('referralCode', res?.data?.data?.referralCode);
          showToast('success', res?.data?.message);
          dispatch(setIsOpenDraftListDialog(false));
        } else {
          showToast('error', res?.data?.message);
        }
      }
      // setSubmitting(false);
    });
  };

  return (
    <div className="flex flex-col w-full h-full gap-2 py-4">
      <div className="flex flex-col gap-4 px-4">
        {/* select outlet  */}
        {/* {userData?.userType === 'ADMIN' && (
          <div className="">
            <ATMSelect
              value={outlet}
              onChange={(newValue) => dispatch(setOutlet(newValue))}
              options={outletData}
              valueAccessKey="_id"
              getOptionLabel={(option) => option?.name}
              placeholder="Please Select Type"
              isClearable={false}
              isLoading={outletDataLoading}
            />
          </div>
        )} */}
        <div className="">
          <ATMButton
            variant="outlined"
            color="error"
            onClick={() => dispatch(setIsOpenDraftListDialog(true))}
          >
            Pick from drafts
          </ATMButton>
        </div>

        {/* Customer Search Box and add new  */}
        <div className="flex items-center gap-4">
          <div className="flex-1">
            <ATMSelect
              value={values?.customer}
              onChange={(newValue) => {
                setFieldValue('customer', newValue);
              }}
              placeholder="Select Customer"
              name="customer"
              options={customerData}
              label=""
              getOptionLabel={(option) => option?.customerName}
              valueAccessKey="_id"
              isLoading={customerLoading}
            />
          </div>

          <div>
            <ATMButton
              compact
              extraClasses={`p-2.5`}
              onClick={() => dispatch(setIsOpenCustomerDialog(true))}
            >
              <IconPlus size={14} />
            </ATMButton>
          </div>
        </div>
      </div>
      <div className="flex flex-col flex-1 overflow-auto ">
        <div className="flex gap-2 p-4 py-1 text-sm font-medium text-slate-800">
          <div className="flex-1 truncate">Item</div>
          <div className="min-w-[60px]">Qty</div>
          <div className="min-w-[100px] text-right">Price</div>
          <div className="min-w-[20px]"></div>
        </div>

        <div className="flex-1 overflow-auto">
          {cartItems?.map((item) => {
            return (
              <div
                key={item?._id}
                className="flex items-center gap-2 p-4 py-2 text-slate-800 even:bg-gray-100"
              >
                <div className="flex-1 overflow-hidden text-xs">
                  <div className="w-full truncate">{item?.itemName}</div>
                  <div className="font-medium text-pink-800">
                    {item?.sellingPrice}
                  </div>
                </div>
                <div className="min-w-[60px] flex bg-primary rounded items-center p-0.5">
                  <IconMinus
                    onClick={() => onQuantityChange(item?._id, 'DECREMENT')}
                    size={14}
                    className="text-white cursor-pointer"
                  />
                  <div className="flex justify-center flex-1 text-xs text-white select-none ">
                    {item?.quantity}
                  </div>
                  <IconPlus
                    onClick={() => onQuantityChange(item?._id, 'INCREMENT')}
                    size={14}
                    className="text-white cursor-pointer"
                  />
                </div>
                <div className="min-w-[100px] text-xs font-medium select-none text-right ">
                  {(item?.sellingPrice * item?.quantity).toFixed(2)}
                </div>
                <div
                  className="min-w-[20px] text-right cursor-pointer"
                  onClick={() => onRemove(item?._id)}
                >
                  <IconTrash size={18} className="text-error" />
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {/* Amount Calculation */}
      <div className="border-t">
        {/* Total */}
        <div className="flex gap-2 p-4 py-1 text-sm font-medium text-slate-800">
          <div className="flex-1 truncate">Total {cartItems?.length}</div>
          <div className="min-w-[60px]">
            {cartItems?.reduce((sum, item) => {
              return sum + item?.quantity;
            }, 0)}
          </div>
          <div className="min-w-[100px] text-right">
            {/* {CURRENCY} */}
            {cartItems
              ?.reduce((sum, item) => {
                return sum + item?.quantity * item?.sellingPrice;
              }, 0)
              .toFixed(2)}
          </div>
        </div>

        <AmountSummaryCard items={cartItems} customer={values?.customer} />
        {isOpenAddDialog && (
          <PaymentFormWrapper
            onClose={() => dispatch(setIsOpenAddDialog(false))}
            items={cartItems}
            customerId={values?.customer}
            formikProps={formikProps}
          />
        )}
        {isOpenDraftListDialog && (
          <DraftList
            onClose={() => dispatch(setIsOpenDraftListDialog(false))}
            onEdit={(item) => {
              handleGetDraftData(item?._id);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default CartSummarySection;
