import { IconEdit, IconPlus, IconTrash } from '@tabler/icons-react';
import { FieldArray, FormikProps } from 'formik';
import { useCallback } from 'react';
import { ATMButton } from 'src/components/atoms/ATMButton/ATMButton';
import ATMCheckbox from 'src/components/atoms/FormElements/ATMCheckbox/ATMCheckbox';
import ATMNumberField from 'src/components/atoms/FormElements/ATMNumberField/ATMNumberField';
import ATMSelect from 'src/components/atoms/FormElements/ATMSelect/ATMSelect';
import ATMTextField from 'src/components/atoms/FormElements/ATMTextField/ATMTextField';
import MOLFormDialog from 'src/components/molecules/MOLFormDialog/MOLFormDialog';
import { useFetchData } from 'src/hooks/useFetchData';
import { useGetPaymntModesQuery } from 'src/modules/PaymentMode/service/PaymentModeServices';
import { CURRENCY } from 'src/utils/constants';

type Props = {
  formikProps: FormikProps<any>;
  onClose: () => void;
  payAbleAmount: number;
  handleApplyPayment: any;
  onDraft: any;
  previewData: any;
  isPreviewed: boolean;
  onModify: () => void;
  previewIsLoading: boolean;
  isDraftSubmitting: boolean;
  loyaltyPoints: number;
};

const PaymentFormLayout = ({
  formikProps,
  onClose,
  onDraft,
  payAbleAmount,
  handleApplyPayment,
  previewData,
  isPreviewed,
  onModify,
  previewIsLoading,
  loyaltyPoints,
  isDraftSubmitting,
}: Props) => {
  const { values, setFieldValue, isSubmitting } = formikProps;

  const { data: paymentData, isLoading: paymentLoading } = useFetchData(
    useGetPaymntModesQuery,
    {
      body: {
        isPaginationRequired: false,
        filterBy: JSON.stringify([
          {
            fieldName: 'isActive',
            value: true,
          },
        ]),
      },
    },
  );

  const calculateTotalReceived = useCallback(() => {
    return values.amountReceived.reduce(
      (total: number, payment: any) => total + (Number(payment.amount) || 0),
      0,
    );
  }, [values.amountReceived]);

  const isLastPaymentModeFilled = () => {
    if (values?.amountReceived?.length === 0) {
      return true;
    }
    const lastPaymentMode =
      values?.amountReceived?.[values.amountReceived.length - 1];
    return (
      lastPaymentMode && lastPaymentMode.paymentModeId && lastPaymentMode.amount
    );
  };

  return (
    <>
      <MOLFormDialog
        title="Payment"
        onClose={onClose}
        isSubmitting={isSubmitting}
        isSubmitButtonDisabled={!isPreviewed}
        isDraftSubmitting={isDraftSubmitting}
        draftbtn
        onDraft={() => onDraft(values)}
      >
        <div className="flex gap-4">
          <div className="border rounded-lg  w-[300px] h-[400px] flex flex-col justify-between ">
            <div className="flex flex-col gap-2">
              {/* payAbleAmount */}
              <div className="flex items-center justify-between p-2 text-sm font-medium tracking-wide border-b">
                <span> Summary</span>
                <span>
                  {' '}
                  {/* Modify */}
                  {isPreviewed && (
                    <div className="flex justify-end text-xs font-medium text-blue-500 ">
                      <div
                        className="flex gap-1 cursor-pointer"
                        onClick={() => {
                          onModify();
                          setFieldValue('amountReceived', [
                            { paymentModeId: '', amount: '' },
                          ]);
                        }}
                      >
                        <IconEdit size={18} /> Modify
                      </div>
                    </div>
                  )}
                </span>
              </div>

              {/* Shipping Charges */}
              <div className="flex flex-col gap-1 px-2">
                <div className="flex items-center justify-between p-1 text-xs font-regular">
                  <div className=" text-neutral-40"> Sub Total</div>{' '}
                  <div className="font-medium">
                    {CURRENCY} {payAbleAmount.toFixed(2)}
                  </div>
                </div>
                <div className="">
                  {isPreviewed ? (
                    <div className="flex items-center justify-between p-1 text-xs font-regular">
                      <div className=" text-neutral-40"> Shipping Charges</div>{' '}
                      <div className="font-medium">
                        {CURRENCY} {values?.shippingCharges}
                      </div>
                    </div>
                  ) : (
                    <ATMNumberField
                      name="shippingCharges"
                      value={values?.shippingCharges}
                      onChange={(newValue) =>
                        setFieldValue('shippingCharges', newValue)
                      }
                      placeholder="Enter Shipping Charges"
                      label="Shipping Charges"
                    />
                  )}
                </div>

                {/* Coupon Discount */}
                <div className="">
                  {isPreviewed ? (
                    previewData?.invoiceData?.couponDiscount ? (
                      <div className="flex justify-between p-1 text-xs font-regular">
                        <div className="flex flex-col gap-1 text-neutral-40">
                          Coupon Applied{' '}
                          <div className="px-2 py-[2px] font-semibold text-green-800 bg-green-100 rounded-md w-fit text-[10px]">
                            {values?.couponCode}
                          </div>
                        </div>
                        <div className="flex flex-col items-end gap-1">
                          <span className="font-medium text-green-600">
                            - {CURRENCY}{' '}
                            {previewData?.invoiceData?.couponDiscount.toFixed(
                              2,
                            )}
                          </span>
                        </div>
                      </div>
                    ) : null
                  ) : (
                    <ATMTextField
                      name="couponCode"
                      value={values?.couponCode}
                      onChange={(e) =>
                        setFieldValue('couponCode', e.target.value)
                      }
                      placeholder="Enter Coupon Code"
                      label="Coupon Code"
                    />
                  )}
                </div>

                {/* GiftCard Discount */}
                <div className="">
                  {isPreviewed ? (
                    previewData?.invoiceData?.giftCardDiscount ? (
                      <div className="flex justify-between p-1 text-xs font-regular">
                        <div className="flex flex-col gap-1 text-neutral-40">
                          GiftCard Amount{' '}
                          <div className="px-2 py-[2px] text-green-800 bg-green-100 rounded-md w-fit text-[10px]">
                            {values?.giftCardCode}
                          </div>
                        </div>
                        <span className="font-medium text-green-600">
                          - {CURRENCY}{' '}
                          {previewData?.invoiceData?.giftCardDiscount.toFixed(
                            2,
                          )}
                        </span>
                      </div>
                    ) : null
                  ) : (
                    <ATMTextField
                      name="giftCardCode"
                      value={values?.giftCardCode}
                      onChange={(e) =>
                        setFieldValue('giftCardCode', e.target.value)
                      }
                      placeholder="Enter Gift Card Code"
                      label="Gift Card Code"
                    />
                  )}
                </div>

                {/* Loyalty Point */}
                <div className="">
                  {isPreviewed ? (
                    previewData?.invoiceData?.loyaltyPointsDiscount ? (
                      <div className="flex items-center justify-between p-1 text-xs font-regular">
                        <div className=" text-neutral-40"> Loyalty Points</div>{' '}
                        <span className="font-medium text-green-600">
                          - {CURRENCY}{' '}
                          {previewData?.invoiceData?.loyaltyPointsDiscount.toFixed(
                            2,
                          )}
                        </span>
                      </div>
                    ) : null
                  ) : (
                    <div className="flex items-center justify-between">
                      <ATMCheckbox
                        checked={values?.useLoyaltyPoints}
                        onChange={() =>
                          setFieldValue(
                            'useLoyaltyPoints',
                            !values?.useLoyaltyPoints,
                          )
                        }
                        size="small"
                        label="Use Loyalty Points"
                        disabled={isPreviewed}
                      />
                      <div className="text-[12px] font-medium text-blue-800">
                        {loyaltyPoints ? loyaltyPoints.toFixed(2) : null}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div>
              {/* Loyalty Points Earned */}
              {isPreviewed && previewData?.pointsToAdd ? (
                <div className="px-4 py-1 text-xs font-medium text-green-900">
                  Loyalty Points Earned :{' '}
                  {(previewData?.pointsToAdd).toFixed(2)}
                </div>
              ) : null}

              {/* Payable Amount APPLY BUTTON */}
              <div>
                {isPreviewed && previewData?.invoiceData?.totalAmount ? (
                  <div className="flex gap-2 px-4 py-2 text-sm bg-yellow-100 rounded-b-lg">
                    <div className="flex-1 font-medium ">Payable Amount</div>
                    <div className="min-w-[60px]  font-medium text-slate-800 text-right">
                      {CURRENCY}{' '}
                      {previewData?.invoiceData?.totalAmount.toFixed(2) || 0}
                    </div>
                  </div>
                ) : (
                  <div className="flex justify-end px-2 mb-2">
                    <ATMButton
                      onClick={() => {
                        handleApplyPayment(values, setFieldValue);
                      }}
                      isLoading={previewIsLoading}
                    >
                      Apply
                    </ATMButton>
                  </div>
                )}
              </div>
            </div>
          </div>
          {!isPreviewed ? (
            <div className=" w-[500px]"></div>
          ) : (
            <div className="flex flex-col justify-between p-2 border rounded-lg w-[500px]">
              <div className="flex flex-col gap-2 ">
                {/* Amount Received */}
                <div className="pb-2 text-sm font-medium tracking-wide border-b border-dashed text-slate-500">
                  Amount Received
                </div>
                <div className="grid items-end grid-cols-2 gap-2 text-xs font-medium tracking-wide text-slate-500 ">
                  <div className="col-span-1 ">Payment Mode</div>
                  <div className="">Amount</div>
                </div>
                {/* FieldArray For PaymentMode */}
                <FieldArray name="amountReceived">
                  {({ insert, remove, push }) => (
                    <div className="flex flex-col gap-5 ">
                      {values.amountReceived.length > 0 &&
                        values.amountReceived.map(
                          (payment: any, index: number) => (
                            <div
                              key={index}
                              className="grid items-end grid-cols-2 gap-2"
                            >
                              <div className="col-span-1">
                                <ATMSelect
                                  name={`amountReceived.${index}.paymentModeId`}
                                  value={
                                    values.amountReceived[index].paymentModeId
                                  }
                                  onChange={(newValue) =>
                                    setFieldValue(
                                      `amountReceived.${index}.paymentModeId`,
                                      newValue,
                                    )
                                  }
                                  label=""
                                  options={paymentData}
                                  valueAccessKey="_id"
                                  placeholder="Payment mode"
                                  getOptionLabel={(option: any) =>
                                    option?.modeName
                                  }
                                  isOptionDisabled={(option) => {
                                    return (
                                      values?.amountReceived?.findIndex(
                                        (modes: any) =>
                                          modes?.paymentModeId?._id ===
                                          option._id,
                                      ) > -1
                                    );
                                  }}
                                  isLoading={paymentLoading}
                                />
                              </div>
                              <div className="flex items-center gap-2">
                                <ATMNumberField
                                  name={`amountReceived.${index}.amount`}
                                  value={values.amountReceived[index].amount}
                                  onChange={(newValue) => {
                                    setFieldValue(
                                      `amountReceived.${index}.amount`,
                                      newValue,
                                    );
                                  }}
                                  label=""
                                  placeholder="Enter Payment"
                                  isAllowDecimal
                                />
                                <div className="">
                                  <ATMButton
                                    type="button"
                                    onClick={() => remove(index)}
                                    variant="text"
                                    extraClasses=" text-red-500"
                                  >
                                    <IconTrash />
                                  </ATMButton>
                                </div>
                              </div>
                            </div>
                          ),
                        )}
                      <div>
                        {previewData?.invoiceData?.totalAmount?.toFixed(2) <
                        calculateTotalReceived() ? (
                          <div className="text-xs font-semibold text-red-500">
                            Total received amount cannot be greater than payable
                            amount
                          </div>
                        ) : null}
                      </div>
                      <div
                        className={`flex items-center justify-center gap-1 py-2 border border-dashed rounded cursor-pointer bg-gray-50  ${
                          !isLastPaymentModeFilled()
                            ? 'opacity-50 cursor-not-allowed'
                            : ''
                        }`}
                        onClick={() => {
                          if (isLastPaymentModeFilled()) {
                            push({ paymentModeId: '', amount: '' });
                          }
                        }}
                      >
                        <IconPlus className="size-[0.75rem]" />
                        <span className="text-xs font-semibold">
                          Add Payment Mode
                        </span>
                      </div>
                    </div>
                  )}
                </FieldArray>
              </div>
              {/*due balance */}
              <div className="flex items-center justify-end gap-2 pt-2 text-sm font-medium tracking-wide text-red-500 ">
                <div>Balance Due :</div>
                <div>
                  {CURRENCY}{' '}
                  {(
                    previewData?.invoiceData?.totalAmount -
                      calculateTotalReceived() || 0
                  ).toFixed(2)}
                </div>
              </div>
            </div>
          )}
        </div>
      </MOLFormDialog>
    </>
  );
};

export default PaymentFormLayout;
