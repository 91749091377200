import { array, number, object } from 'yup';
import POS from './POS';
import { Form, Formik, FormikHelpers } from 'formik';
import { AppDispatch, RootState } from 'src/store';
import { useDispatch, useSelector } from 'react-redux';
import { showToast } from 'src/utils/showToaster';
import { setIsOpenAddDialog, setIsOpenCustomerDialog } from './slice/CartSlice';
import { useAddInvoiceMutation } from './service/POSServices';
import AddCustomerFormWrapper from './components/AddCustomerForm/AddCustomerFormWrapper';
import { useNavigate } from 'react-router-dom';

type Props = {};

const POSWrapper = (props: Props) => {
  const navigate = useNavigate();
  const { outlet } = useSelector((state: RootState) => state.auth);
  const { isOpenCustomerDialog, previewData } = useSelector(
    (state: RootState) => state.cart,
  );
  const dispatch = useDispatch<AppDispatch>();
  const [addInvoice] = useAddInvoiceMutation();

  const initialValues = {
    customer: null,
    items: [],
    couponCode: '',
    shippingCharges: 0,
    amountReceived: [
      {
        paymentModeId: '',
        amount: 0,
      },
    ],
    giftCardCode: '',
    useLoyaltyPoints: false,
    referralCode: '',
  };
  const validationSchema = object().shape({
    customer: object().required('Please select customer').nullable(),
    amountReceived: array().of(
      object().shape({
        paymentModeId: object().required('Payment mode is required'),
        amount: number()
          .required('Amount is required')
          .typeError('Amount must be a number')
          .min(0, 'Amount must be positive')
          .test('totalReceived', '', function (value, context) {
            const totalReceived =
              context?.options?.context?.amountReceived?.reduce(
                (sum: number, item: any) => sum + (Number(item.amount) || 0),
                0,
              );
            return (
              totalReceived <= previewData?.invoiceData?.totalAmount.toFixed(2)
            );
          }),
      }),
    ),
  });

  const handleSubmit = (
    values: any,
    { resetForm, setSubmitting }: FormikHelpers<any>,
  ) => {
    let formattedValues = {
      customerId: values?.customer?._id,
      items: values?.items?.map((item: any) => {
        return {
          itemId: item?._id,
          quantity: item?.quantity,
          itemType: item?.type,
        };
      }),
      couponCode: values?.couponCode,
      shippingCharges: values?.shippingCharges || 0,
      amountReceived: values?.amountReceived?.map((el: any) => {
        return {
          paymentModeId: el?.paymentModeId?._id,
          amount: el?.amount,
        };
      }),
      giftCardCode: values?.giftCardCode,
      useLoyaltyPoints: values?.useLoyaltyPoints,
      referralCode: '',
      outletId: (outlet as any)?._id,
    };
    addInvoice(formattedValues).then((res: any) => {
      if (res?.error) {
        showToast('error', res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast('success', res?.data?.message);
          resetForm();
          dispatch(setIsOpenAddDialog(false));
          navigate(`/invoice/receipt/${res?.data?.data?._id}`);
        } else {
          showToast('error', res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };
  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {(formikProps) => (
          <Form>
            <POS formikProps={formikProps} />
          </Form>
        )}
      </Formik>

      {isOpenCustomerDialog && (
        <AddCustomerFormWrapper
          onClose={() => dispatch(setIsOpenCustomerDialog(false))}
        />
      )}
    </div>
  );
};

export default POSWrapper;
