import TopCustomerAndProductsListing from './TopCustomerAndProductsListing';

type Props = {};

const TopCustomerAndProductsListingWrapper = (props: Props) => {
  return (
    <>
      <TopCustomerAndProductsListing />
    </>
  );
};

export default TopCustomerAndProductsListingWrapper;
