import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import ATMCircularProgress from '../atoms/ATMCircularProgress/ATMCircularProgress';
import { AppDispatch, RootState } from '../../store';
import { useFetchData } from '../../hooks/useFetchData';
import { useGetRolesOfAnAdminQuery } from '../../modules/AdminRole/service/AdminRoleServices';
import {
  setIsLogin,
  setOutlet,
  setOutlets,
  setPermissions,
  setReturnUrl,
  setUserData,
} from '../../slices/AuthSlice';

type Props = {
  children: JSX.Element;
};

const AuthWrapper = ({ children }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const { isLogin, userData } = useSelector((state: RootState) => state?.auth);
  const { pathname } = useLocation();
  const [isChecking, setIsChecking] = useState(true);

  const { data, isLoading } = useFetchData(useGetRolesOfAnAdminQuery, {
    dataType: 'VIEW',
    // options: {
    //   skip: userData?.userType === 'ADMIN',
    // },
  });

  useEffect(() => {
    if (!isLoading) {
      const userData = {
        userName: (data as any)?.data?.userdata?.userName,
        name: (data as any)?.data?.userdata?.name,
        userId: (data as any)?.data?.userdata?.userID,
        userType: (data as any)?.data?.userdata?.userType,
        email: (data as any)?.data?.userdata?.email,
        mobile: (data as any)?.data?.userdata?.phone,
      };
      dispatch(setOutlets((data as any)?.data?.outlets));
      dispatch(setOutlet((data as any)?.data?.outlets?.[0]));
      data && dispatch(setUserData(userData));
      data && dispatch(setPermissions((data as any)?.data?.permissions));
      setTimeout(() => {
        setIsChecking(false);
      }, 500);
    } else {
      setIsChecking(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, data]);

  if (isChecking) {
    return (
      <div className="flex flex-col items-center justify-center w-screen h-screen">
        <ATMCircularProgress />
        <div className="text-center">
          Please wait, We are checking your authentication
        </div>
      </div>
    );
  }

  if (!isLogin) {
    dispatch(setReturnUrl(pathname));
    return <Navigate to={'/login'} />;
  } else {
    return children;
  }
};

export default AuthWrapper;
