import React from 'react';

type Props = {};

const PageNotFoundImage = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 400 400"
      height="200"
      width="200"
      id="To-Do-List-No-Tasks--Streamline-Bruxelles.svg"
    >
      <desc>
        To Do List No Tasks Streamline Illustration: https://streamlinehq.com
      </desc>
      <g id="To-Do-List-No-Tasks--Streamline-Bruxelles.svg">
        <path
          id="shadow"
          fill="#00522d"
          d="M311.208 113.6884c-7.798 -0.516 -54.034 2.05 -199.922 -0.996 -4.396 -0.092 -9.198 -1.586 -12.32 -1.586 -3.898 0 -5.1262 2.362 -5.4742 3.376 -1.708 5.01 -2.7678 17.814 -2.5398 20.114 0.302 3.026 0.6538 72.8956 -0.5962 84.6876 -0.372 3.504 -2.452 23.368 -8.708 70.352 -0.118 0.884 -6.122 83.024 -6.142 83.302 -0.09 1.238 -0.204 2.778 1 4.092 1.174 1.28 2.714 1.298 3.22 1.306 30.594 0.304 116.934 4.9 120.016 4.902 13.8622 0.01 62.1382 3.578 94.7962 7.368 0.324 0.038 0.558 0.026 0.846 -0.004 3.224 -0.338 5.26 0.016 6.722 -1.756 1.04 -1.256 0.876 -2.636 0.822 -3.09 -0.192 -1.586 -0.282 -2.734 -0.362 -3.748 -0.782 -9.988 -5.43 -11.374 4.33 -90.038 1.512 -12.174 1.672 -11.534 2.832 -38.354 0.37 -8.554 0.678 -16.856 1.328 -22.95 1.216 -11.426 4.922 -46.1956 4.438 -72.0236 0.006 -35.114 2.038 -44.55 -4.29 -44.956l0.004 0.002Z"
          strokeWidth="1"
        ></path>
        <g id="list">
          <path
            id="vector"
            fill="#ffffff"
            d="M282.952 380.936c-27.56 -3.198 -78.05 -7.248 -94.958 -7.38 -2.448 -0.02 -92.25 -4.624 -120.03 -4.9 -0.39 -0.006 -1.294 -0.016 -1.958 -0.74 -0.694 -0.756 -0.628 -1.652 -0.544 -2.782 0.022 -0.29 6.014 -82.312 6.132 -83.196 6.25 -46.948 8.354 -66.882 8.712 -70.4 1.284 -11.4 0.898 -82.1326 0.594 -85.0786 -0.25 -2.136 0.85 -14.628 2.456 -19.338 1.516 -4.434 8.682 -0.76 16.1 -0.608 151.964 3.174 189.912 0.334 199.852 0.994 3.03 0.194 3.192 3.812 2.828 26.266 -0.454 28.02 0.748 38.398 -4.608 88.7726 -0.536 5.034 -0.68 7.898 -1.336 23.052 -1.12 25.934 -1.236 25.44 -2.822 38.236 -2.822 22.752 -8.696 70.096 -4.786 86.886 0.262 1.13 0.344 2.176 0.448 3.5 0.298 3.8 0.716 4.64 -0.064 5.584 -0.634 0.768 -1.372 0.82 -3.346 0.96 -2.492 0.178 -2.41 0.206 -2.674 0.176l0.004 -0.004Z"
            strokeWidth="1"
          ></path>
          <path
            id="vector_2"
            fill="#00522d"
            d="M99.4222 108.2598c150.8918 3.126 190.1858 0.37 199.7718 0.99 2.21 0.142 0.818 20.702 1.012 41.528 0.254 27.08 -3.616 63.9482 -4.606 73.3562 -0.812 7.706 -1.526 36.058 -2.576 47.664 -1.08 11.932 -11.048 78.27 -6.144 99.316 0.412 1.772 0.326 3.33 0.784 7.124 0.096 0.788 0.014 0.578 -4.494 0.958 -26.674 -3.096 -79.178 -7.518 -95.2158 -7.388 -0.046 0 -92.724 -4.594 -119.972 -4.902 -1.084 -0.012 -0.83 -0.334 -0.704 -2.71 0.01 -0.202 5.942 -81.212 6.05 -82.028 6.776 -51.164 8.706 -70.344 8.73 -70.546 1.334 -11.6802 0.872 -82.8882 0.582 -85.3622 -0.21 -1.796 0.836 -14.078 2.374 -18.574 0.858 -2.51 7.582 0.436 14.41 0.578l-0.002 -0.004Zm-12.248 -5.086c-3.898 0 -5.126 2.36 -5.474 3.376 -1.712 5.01 -2.768 17.816 -2.538 20.116 0.328 3.298 0.708 71.918 -0.598 84.7162 -0.362 3.554 -2.538 23.64 -8.706 70.324 -0.12 0.902 -6.12 83.02 -6.14 83.298 -0.092 1.238 -0.204 2.782 1 4.094 1.174 1.28 2.714 1.3 3.22 1.306 28.926 0.34 117.888 4.886 120.016 4.902 13.4298 0.1 60.6758 3.396 94.7938 7.37 0.326 0.038 0.56 0.026 0.848 -0.004 3.234 -0.338 5.264 0.012 6.724 -1.756 1.04 -1.26 0.874 -2.64 0.818 -3.094 -0.19 -1.578 -0.28 -2.724 -0.36 -3.736 -0.754 -9.64 -5.436 -11.364 4.33 -90.044 1.508 -12.156 1.684 -11.754 2.834 -38.366 0.372 -8.604 0.678 -16.836 1.33 -22.966 1.216 -11.424 4.674 -46.1862 4.434 -71.9942 -0.308 -33.094 2.456 -44.518 -4.29 -44.956 -9.114 -0.592 -52.312 2.08 -199.9218 -0.996 -4.398 -0.092 -9.198 -1.586 -12.32 -1.586v-0.004Z"
            strokeWidth="1"
          ></path>
          <path
            id="vector_3"
            fill="#c2ffd2"
            d="M100.834 321.292c-0.028 1.6 -0.648 4.798 -0.624 6.572 0.086 6.486 -0.512 7.208 0.024 7.266 4.18 0.454 4.286 -0.032 5.886 0.188 1.28 0.174 2.532 0.264 3.8 -0.014 0.576 -0.126 0.502 0.414 2.988 0.038 3.26 -0.494 3.278 -0.158 4.264 -0.132 3.288 0.09 7.34 -0.274 9.184 0.436 0.534 0.206 1.13 0.236 1.702 0.348 1.008 0.196 0.474 -1.008 0.512 -1.506 0 -0.056 1.924 -23.592 2.332 -26.726 0.3 -2.308 -4.352 -1.112 -20.942 -2.252 -4.582 -0.314 -4.164 0.15 -6.28 -0.22 -3.784 -0.662 -2.614 2.856 -2.842 16.004l-0.004 -0.002Z"
            strokeWidth="1"
          ></path>
          <path
            id="vector_4"
            fill="#c2ffd2"
            d="M111.6404 280.22c0.004 -0.076 16.04 -0.804 18.16 0.07 0.558 0.23 1.2 0.266 1.806 0.38 1.374 0.26 -0.004 -0.296 1.876 -17.832 0.342 -3.184 0.798 -8.054 0.798 -8.054 0.304 -2.246 0.7 -3.112 -0.666 -3.47 -0.704 -0.184 -3.654 -0.596 -6.426 -0.506 -8.348 0.27 -8.836 -0.406 -15.68 -0.69 -1.14 -0.048 -2.27 0.126 -3.4 0.004 -1.81 -0.194 -3.24 -0.75 -3.466 2.138 -0.088 1.14 -0.356 5.21 -0.202 6.356 0.434 3.204 -0.304 3.63 -0.004 4.752 0.602 2.252 -0.75 4.094 -0.788 16.102 0 0.534 0.192 0.326 2.13 0.502 0.262 0.024 0.586 0.21 0.778 0.112 0.62 -0.31 1.07 -0.206 5.084 0.136Z"
            strokeWidth="1"
          ></path>
          <path
            id="vector_5"
            fill="#c2ffd2"
            d="M110.608 195.0578c-1.744 -0.034 -1.96 1.576 -1.98 3.916 -0.094 11.6742 0.03 11.6562 -0.108 12.8702 -1.256 11.132 0.136 2.166 -0.75 12.908 3.428 0.75 0.506 -0.062 7.382 0.554 1.308 0.118 2.608 -0.458 3.922 -0.024 0.226 0.074 1.754 -0.506 2.496 -0.362 0.998 0.194 1.994 -0.348 2.96 0.098 1.256 -0.174 2.51 -0.228 3.766 0.018 0.262 0.052 1.266 -0.172 1.838 -0.15 4.506 0.174 5.262 1.01 5.812 0.886 0.184 -0.042 0.478 -0.334 0.454 -0.398 -0.314 -0.746 0.046 -1.454 0.08 -2.176 1.198 -26.1902 3.424 -26.5802 1.13 -26.9122 -5.698 -0.826 -2.992 -0.262 -11.548 -0.47 -0.418 -0.01 -0.84 0.108 -1.256 0.084 -1.806 -0.104 -9.668 -1.038 -11.426 -0.62 -0.408 0.098 -0.562 -0.18 -2.772 -0.226v0.004Z"
            strokeWidth="1"
          ></path>
          <path
            id="vector_6"
            fill="#c2ffd2"
            d="M114.4682 169.7534c9.756 0.38 8.4 -0.296 19.714 -0.09 3.592 0.066 4.526 0.67 4.966 0.77 1.056 0.238 0.786 0.44 1.12 -6.726 0.028 -0.596 0.424 -3.766 0.638 -6.116 0.274 -3.024 0.064 -2.52 0.572 -7.424 0.912 -8.8 1.14 -8.466 0.29 -8.958 -0.924 -0.536 -5.24 -0.774 -6.388 -0.732 -5.52 0.202 -5.914 0.222 -11.852 -0.296 -6.938 -0.604 -6.172 -0.196 -6.956 -0.234 -2.34 -0.112 -4.13 -1.088 -4.222 2.444 0 0.046 -0.268 4.888 -0.178 5.732 0.336 3.184 -0.066 3.288 0.042 4.976 0.238 3.678 -0.678 6.036 -0.6 9.508 0.168 7.5 -1.82 6.966 2.852 7.148l0.002 -0.002Z"
            strokeWidth="1"
          ></path>
          <path
            id="vector_7"
            fill="#00522d"
            d="M113.4322 136.134c3.218 0.52 6.336 0.228 9.446 0.366 17.682 0.78 15.294 -0.24 19.352 0.708 2.552 0.596 3.922 2.294 3.452 4.718 -0.602 3.106 -2.006 25.4082 -2.172 28.8402 -0.052 1.078 -0.564 3.5158 -1.586 3.6338 -1.736 0.202 -0.404 0.502 -4.606 -0.54 -6.078 -1.506 -27.962 2.14 -29.098 -2.918 -0.296 -1.318 -0.668 -2.598 -0.366 -3.972 0.094 -0.432 0.044 -4.1218 0.318 -6.8718 0.96 -9.572 0.492 -8.452 0.634 -13.02 0.08 -2.604 0.708 -7.7722 0.722 -7.8242 0.394 -1.914 2.162 -3.2398 3.902 -3.1238l0.002 0.0038Zm1.036 33.62c9.756 0.38 8.4 -0.296 19.714 -0.09 3.592 0.066 4.526 0.6702 4.966 0.7702 1.056 0.238 0.786 0.4398 1.12 -6.7262 0.028 -0.596 0.424 -3.766 0.638 -6.116 0.274 -3.024 0.064 -2.52 0.572 -7.424 0.912 -8.8 1.14 -8.4658 0.29 -8.9578 -0.924 -0.536 -5.24 -0.7742 -6.388 -0.7322 -5.52 0.202 -5.914 0.2222 -11.852 -0.2958 -6.938 -0.604 -6.172 -0.1962 -6.956 -0.2342 -2.34 -0.112 -4.13 -1.088 -4.222 2.444 0 0.046 -0.268 4.888 -0.178 5.732 0.336 3.184 -0.066 3.2882 0.042 4.9762 0.238 3.678 -0.678 6.0358 -0.6 9.5078 0.168 7.5 -1.82 6.966 2.852 7.148l0.002 -0.002Z"
            strokeWidth="1"
          ></path>
          <path
            id="vector_8"
            fill="#00522d"
            d="M104.172 222.68c0.004 -0.048 1.178 -15.478 1.036 -16.354 -0.442 -2.744 0.44 -8.692 0.586 -11.168 0.102 -1.744 1.772 -3.982 4.362 -3.62 0.104 0.014 20.224 0.722 20.702 0.666 4.404 -0.514 11.838 -0.182 11.294 4.216 -0.996 8.058 -2.368 28.798 -2.336 28.906 0.302 0.986 -0.012 1.914 -0.286 2.824 -1.038 3.45 -4.976 0.69 -8.616 0.684 -6.06 -0.008 -15.412 0.24 -15.412 0.24 -2.598 -0.056 -1.66 -0.412 -3.236 -0.276 -1.88 0.16 -3.702 -0.172 -5.506 -0.652 -1.158 -0.308 -2.02 -0.802 -2.224 -2.18 -0.14 -0.948 -0.474 -1.878 -0.366 -3.284l0.002 -0.002Zm6.436 -27.622c-1.744 -0.034 -1.96 1.576 -1.98 3.916 -0.094 11.674 0.03 11.656 -0.108 12.87 -1.256 11.132 0.136 2.166 -0.75 12.908 3.428 0.75 0.506 -0.062 7.382 0.554 1.308 0.118 2.608 -0.458 3.922 -0.024 0.226 0.074 1.754 -0.506 2.496 -0.362 0.998 0.194 1.994 -0.348 2.96 0.098 1.256 -0.174 2.51 -0.228 3.766 0.018 0.262 0.052 1.266 -0.172 1.838 -0.15 4.506 0.174 5.262 1.01 5.812 0.886 0.184 -0.042 0.478 -0.334 0.454 -0.398 -0.314 -0.746 0.046 -1.454 0.08 -2.176 1.198 -26.19 3.424 -26.58 1.13 -26.912 -5.698 -0.826 -2.992 -0.262 -11.548 -0.47 -0.418 -0.01 -0.84 0.108 -1.256 0.084 -1.806 -0.104 -9.668 -1.038 -11.426 -0.62 -0.408 0.098 -0.562 -0.18 -2.772 -0.226v0.004Z"
            strokeWidth="1"
          ></path>
          <path
            id="vector_9"
            fill="#00522d"
            d="M96.3722 333.304c0.404 -3.208 0.194 -6.458 0.698 -9.666 0.652 -4.142 -0.08 -9.084 0.698 -14.99 0.15 -1.14 0.198 -2.288 0.286 -3.438 0.124 -1.622 1.792 -3.354 3.406 -3.452 0.046 0 16.374 0.536 16.42 0.54 7.556 0.702 13.352 -1.378 16.19 2.632 0.81 1.144 0.01 3.668 -0.15 5.59 -0.362 4.366 0.082 0.01 -1.492 19.108 -0.452 5.478 0.628 11.15 -3.494 10.488 -2.504 -0.402 -5.136 -1.262 -6.248 -1.088 -0.956 0.15 -4.68 0.098 -5.624 0.07 -2.458 -0.072 -2.998 0.314 -5.45 0.14 -2.274 -0.16 -3.162 0.46 -5.558 -0.056 -2.608 -0.562 -2.594 0.438 -6.908 -0.746 -2.046 -0.562 -1.958 -0.932 -2.35 -2.152 -0.31 -0.966 -0.478 -1.956 -0.426 -2.978l0.002 -0.002Zm4.46 -12.012c-0.028 1.6 -0.648 4.798 -0.624 6.572 0.086 6.486 -0.512 7.208 0.024 7.266 4.18 0.454 4.286 -0.032 5.886 0.188 1.28 0.174 2.532 0.264 3.8 -0.014 0.576 -0.126 0.502 0.414 2.988 0.038 3.26 -0.494 3.278 -0.158 4.264 -0.132 3.288 0.09 7.34 -0.274 9.184 0.436 0.534 0.206 1.13 0.236 1.702 0.348 1.008 0.196 0.474 -1.008 0.512 -1.506 0 -0.056 1.924 -23.592 2.332 -26.726 0.3 -2.308 -4.352 -1.112 -20.942 -2.252 -4.582 -0.314 -4.164 0.15 -6.28 -0.22 -3.784 -0.662 -2.614 2.856 -2.842 16.004l-0.004 -0.002Z"
            strokeWidth="1"
          ></path>
          <path
            id="vector_10"
            fill="#00522d"
            d="M105.626 246.51c27.288 0.816 30.248 -0.222 32.068 3.162 0.952 1.772 -0.09 2.51 -0.314 7.65 -0.458 10.538 -1.892 17.668 -1.622 23.31 0.104 2.172 -0.962 4.624 -3.076 4.194 -4.114 -0.84 -5.346 -1.246 -6.228 -1.106 -1.162 0.184 -2.34 -0.22 -3.484 0.146 -1.604 -0.808 -11.15 1.212 -20.286 -0.722 -0.356 -0.076 -1.672 -0.59 -1.806 -0.924 -0.632 -1.572 -1.178 -3.136 -0.774 -4.902 0.17 -0.746 0.028 -1.558 0.08 -2.336 1.482 -22.35 0.09 -28.986 5.446 -28.47l-0.004 -0.002Zm6.012 33.708c0.004 -0.076 16.04 -0.804 18.16 0.07 0.558 0.23 1.2 0.266 1.806 0.38 1.374 0.26 -0.004 -0.296 1.876 -17.832 0.342 -3.184 0.798 -8.054 0.798 -8.054 0.304 -2.246 0.7 -3.112 -0.666 -3.47 -0.704 -0.184 -3.654 -0.596 -6.426 -0.506 -8.348 0.27 -8.836 -0.406 -15.68 -0.69 -1.14 -0.048 -2.27 0.126 -3.4 0.004 -1.81 -0.194 -3.24 -0.75 -3.466 2.138 -0.088 1.14 -0.356 5.21 -0.202 6.356 0.434 3.204 -0.304 3.63 -0.004 4.752 0.602 2.252 -0.75 4.094 -0.788 16.102 0 0.534 0.192 0.326 2.13 0.502 0.262 0.024 0.586 0.21 0.778 0.112 0.62 -0.31 1.07 -0.206 5.084 0.136Z"
            strokeWidth="1"
          ></path>
          <path
            id="vector_11"
            fill="#00522d"
            d="M275.902 228.95c-39.854 -3.312 -41.416 -1.546 -59.008 -1.786 -3.756 -0.052 -1.492 -1.314 -41.7718 -1.974 -5.76 -0.094 -6.82 -0.632 -13.418 -0.586 -9.422 0.066 -11.51 0.26 -10.956 -0.97 0.038 -0.084 -0.346 -0.548 -0.272 -0.666 0.194 -0.308 0.44 -0.672 0.756 -0.802 3.536 -1.466 21.796 -0.506 21.852 -0.512 1.07 -0.086 2.148 0.418 3.19 -0.07 15.366 0.77 11.392 0.246 16.458 0.262 1.65 0.006 4.052 0.414 6.758 0.652 6.1678 0.542 5.8958 -0.16 12.4718 0.59 14.864 1.698 28.676 -0.89 34.582 0.586 0.6 0.15 1.272 -0.004 1.914 0.014 13.17 0.386 8.72 0.528 13.968 0.816 4.686 0.256 3.17 -0.156 8.522 0.53 3.682 0.472 5.492 0.212 8.128 0.276 0.98 0.024 2.272 1.516 1.534 2.368 -1.09 1.26 -1.464 1.142 -3.288 1.164 -1.422 0.016 -1.402 -0.056 -1.416 0.108h-0.004Z"
            strokeWidth="1"
          ></path>
          <path
            id="vector_12"
            fill="#00522d"
            d="M206.762 340.508c-1.286 -0.024 -1.876 0.126 -2.256 0.08 -8.6956 -1.052 -58.7416 -2.976 -58.9556 -2.988 -1.904 -0.112 -2.504 -3.584 0.642 -3.556 22.702 0.214 55.7016 2.86 58.1536 2.862 23.818 0.018 21.83 -0.626 43.142 2.026 3.724 0.464 19.962 0.69 21.988 0.75 0.798 0.024 1.546 0.226 1.548 1.022 0 0.426 0.404 0.822 -0.032 1.286 -0.45 0.478 -0.892 0.812 -1.6 0.802 -35.906 -0.544 -14.638 -1.408 -62.63 -2.284Z"
            strokeWidth="1"
          ></path>
          <path
            id="vector_13"
            fill="#00522d"
            d="M262.32 285.586c-10.412 -0.75 -52.324 -0.666 -57.338 -0.98 -44.474 -2.78 -54.012 -2.484 -56.714 -3.068 -1.182 -0.254 -1.914 -1.7 -1.464 -2.818 0.19 -0.474 1.45 -1.104 2.434 -0.694 1.238 0.514 8.058 0.09 12.832 0.718 3.104 0.408 6.224 0.156 9.328 0.296 2.13 0.096 1.416 0.274 4.742 0.398 13.546 0.506 11.764 0.348 15.056 0.512 11.964 0.594 10.872 0.976 13.812 1.106 4.114 0.184 71.716 0.578 71.132 3.016 -0.38 1.586 1.014 1.07 -1.792 1.998 -0.122 -0.47 -0.028 0.38 -12.03 -0.484h0.002Z"
            strokeWidth="1"
          ></path>
          <path
            id="vector_14"
            fill="#00522d"
            d="M153.6262 162.4834c1.73 0.23 3.668 -0.178 5.59 0.154 0.624 0.108 1.3 -0.124 1.952 -0.118 0.052 0 5.4678 0.394 10.3138 0.168 2.402 -0.112 6.918 -0.856 9.582 -0.178 2.898 0.738 5.2242 -0.314 10.0662 -0.004 1.552 0.098 6.168 0.992 10.0238 0.826 5.066 -0.218 7.044 1.258 13.536 0.736 4.198 -0.338 8.326 -0.018 10.554 0.09 5.254 0.254 3.46 -0.176 5.516 -0.206 0.046 0 23.048 0.314 27.17 -0.084 3.804 -0.366 7.584 0.274 9.808 -0.118 1.392 -0.246 2.862 -0.088 4.244 -0.362 0.906 -0.178 1.42 0.32 2.05 0.638 0.488 0.246 0.576 1.092 0.244 1.548 -1.194 1.64 -1.614 1.29 -7.936 1.802 -0.352 0.028 -2.124 -0.146 -3.438 -0.15 -0.37 0 -22.152 0.398 -25.318 0.384 -5.44 -0.024 -0.77 -0.324 -7.832 -0.024 -7.466 0.318 -3.456 -0.304 -10.262 -0.188 -4.216 0.072 -6.796 0.496 -12.266 -0.164 -5.332 -0.644 -4.982 -0.144 -10.4258 -0.568 -2.05 -0.16 -5.6382 -0.87 -8.0102 -0.722 -8.368 0.522 -7.5138 -0.172 -11.9498 0.038 -7.2 0.34 -7.256 0.404 -20.702 0 -1.12 -0.034 -3.598 0.238 -5.446 -0.624 -0.744 -0.348 -0.886 -0.886 -0.426 -1.618 0.92 -1.464 2.524 -1.256 3.368 -1.256h-0.006Z"
            strokeWidth="1"
          ></path>
        </g>
        <g id="fly">
          <path
            id="vector_15"
            fill="#c2ffd2"
            d="M330.596 31.6962c-0.18 -3.532 -1.444 0.018 -5.202 0.854 -1.984 0.44 -5.468 6.916 -3.658 10.506 0.464 0.92 2.04 1.606 3.002 1.248 2.248 -0.836 2.706 -1.754 3.836 -3.906 1.43 -2.72 2.18 -5.604 2.022 -8.7v-0.002Z"
            strokeWidth="1"
          ></path>
          <path
            id="vector_16"
            fill="#c2ffd2"
            d="M315.868 11.96972c-0.352 0.084 -0.722 0.154 -1.092 0.262 -0.948 0.276 -1.704 1.572 -1.472 2.524 0.916 3.786 4.152 5.83008 4.53 6.22008 0.854 0.876 1.528 -0.292 3.818 -1.05008 2.598 -0.86 3.766 -0.376 3.298 -0.976 -2.756 -3.524 -4.332 -6.046 -9.082 -6.98Z"
            strokeWidth="1"
          ></path>
          <path
            id="vector_17"
            fill="#00522d"
            d="M269.288 68.046c0.09 -2.224 0.268 -2.658 -0.576 -2.454 -10.972 2.666 -22.638 13.456 -26.608 32.048 -3.446 16.144 -2.84 11.018 -3.672 22.508 -1.534 21.176 1.782 36.852 7.368 53.198 0.188 0.548 6.004 13.644 11.384 21.144 0.352 0.492 1.356 1.684 0.84 2.354 -3.162 4.092 -21.872 -24.24 -22.996 -64.542 -0.31 -11.14 1.1 -23.606 2.904 -30.764 2.914 -11.572 4.426 -22.162 17.944 -33.052 2.88 -2.32 7.148 -4.692 10.646 -5.662 3.068 -0.85 2.978 -0.198 3.358 -1.848 0.858 -3.728 1.028 -7.514 6.106 -14.16 3.766 -4.93 10.184 -9.674 13.902 -11.154 16.474 -6.552 14.854 -1.312 13.658 -0.924 -2.362 0.764 -10.886 2.402 -14.356 4.774 -8.044 5.498 -13.818 10.268 -16.018 21.566 -0.052 0.268 0.098 0.62 0.258 0.864 0.094 0.142 0.39 0.184 0.6 0.202 5.18 0.41 12.162 4.456 14.114 11.542 2.528 9.184 -3.03 14.276 -7.954 13.598 -4.728 -0.652 -6.508 -4.192 -8.648 -8.466 -1.808 -3.606 -1.974 -7.81 -2.252 -10.774l-0.002 0.002Zm16.082 10.108c-0.418 -6.556 -4.142 -11.136 -11.448 -12.874 -1.83 -0.436 -1.442 1.652 -1.318 4.254 0.18 3.808 1.358 7.078 2.96 10.21 3.294 6.442 9.7 5.46 9.808 -1.59h-0.002Z"
            strokeWidth="1"
          ></path>
          <path
            id="vector_18"
            fill="#00522d"
            d="M335.4 18.49804c-1.55 -1.794 -5.098 -1.68598 -6.566 0.80602 -0.222 0.374 -0.938 0.40798 -1.356 -0.27002 -2.61 -4.21 -6.76 -9.742 -12.632 -9.198 -0.98 0.092 -1.602 0.83 -2.258 1.352 -1.418 1.126 -1.804 2.44 -1.25 4.258 1.1 3.592 3.436 5.45996 5.192 7.07796 0.622 0.576 -1.096 1.304 -1.562 3.77 -0.744 3.956 2.248 6.748 6.144 7.018 0.178 0.012 0.266 0.028 0.312 0.04 -0.194 0.518 -1.35 1.72 -2.206 5.314 -1.534 6.452 3.688 9.708 7.778 7.028 3.702 -2.424 7.092 -10.15 5.144 -16.824 -0.32 -1.092 -0.026 -0.804 0.52 -2.45 0.154 -0.472 0.178 -0.544 0.636 -0.644 2.722 -0.59 4.6 -4.394 2.104 -7.27796Zm-10.594 0.59c-0.564 0.12 -2.486 0.064 -5.236 1.55396 -1.318 0.714 -1.2 0.486 -1.942 -0.1 -0.918 -0.72996 -3.362 -2.66596 -4.13 -5.83396 -0.204 -0.844 0.496 -2.04 1.334 -2.284 0.334 -0.098 0.656 -0.16 1.044 -0.25 4.542 0.906 6.118 3.296 8.93 6.916v-0.002Zm3.592 21.21396 -0.038 0.072c-1.09 2.078 -1.542 2.938 -3.692 3.742 -0.856 0.316 -2.322 -0.296 -2.754 -1.15 -1.746 -3.47 1.668 -9.808 3.524 -10.22 2.676 -0.594 4.166 -2.544 4.664 -2.378 0.384 0.184 0.958 4.874 -1.706 9.936l0.002 -0.002Z"
            strokeWidth="1"
          ></path>
        </g>
        <g id="pin">
          <path
            id="vector_19"
            fill="#c2ffd2"
            d="M172.3522 96.5316c5.2048 0 9.424 -4.2192 9.424 -9.424s-4.2192 -9.424 -9.424 -9.424c-5.2046 0 -9.424 4.2192 -9.424 9.424s4.2194 9.424 9.424 9.424Z"
            strokeWidth="1"
          ></path>
          <path
            id="vector_20"
            fill="#00522d"
            d="m193.0242 115.4796 -13.164 -20.102c7.538 -6.85 2.678 -19.444 -7.506 -19.444 -6.162 0 -11.174 5.012 -11.174 11.174 0 8.138 8.43 13.498 15.754 10.192l13.162 20.098c0.526 0.804 1.608 1.036 2.422 0.504 0.808 -0.53 1.036 -1.614 0.506 -2.422Zm-28.344 -28.372c0 -4.232 3.442 -7.674 7.674 -7.674s7.674 3.442 7.674 7.674 -3.444 7.674 -7.674 7.674 -7.674 -3.444 -7.674 -7.674Z"
            strokeWidth="1"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default PageNotFoundImage;
