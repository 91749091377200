import {
  IconKey,
  IconLogout,
  IconMenu2,
  IconUserPause,
} from '@tabler/icons-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import ChangePasswordFormLayoutWrapper from 'src/modules/UserProfile/changePassword/ChangePasswordFormLayoutWrapper';
import { resetState, setIsLogin, setOutlet } from '../../../slices/AuthSlice';
import { setIsNavBarExpanded } from '../../../slices/SideNavLayoutSlice';
import { AppDispatch, RootState } from '../../../store';
import { clearLocalStorage } from '../../../utils/auth/authUtils';
import ATMSelect from '../FormElements/ATMSelect/ATMSelect';

type Props = {
  hideCollapseMenuButton?: boolean;
  showOutletDropdown?: boolean;
};

const ATMAppHeader = ({
  hideCollapseMenuButton = false,
  showOutletDropdown = false,
}: Props) => {
  const { isNavBarExpanded } = useSelector(
    (state: RootState) => state.sideNavLayout,
  );
  const { userData, outlet, outlets } = useSelector(
    (state: RootState) => state.auth,
  );

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isOpenChangePasswordDialog, setIsOpenChangePassword] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({
    top: 0,
    right: 0,
  });
  const buttonRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (buttonRef.current && !buttonRef?.current?.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const togglePortal = (e: any) => {
    const buttonRect = e.currentTarget.getBoundingClientRect();

    setDropdownPosition({
      top: buttonRect.bottom + 4,
      right: document.body.getBoundingClientRect().width - buttonRect.right,
    });
    setDropdownOpen(!dropdownOpen);
  };

  const handleSignOut = useCallback(() => {
    clearLocalStorage();
    dispatch(resetState(''));
    dispatch(setIsLogin(false));
    navigate('/login');
  }, [dispatch, navigate]);

  const handleNavigate = useCallback(
    (path: string) => {
      navigate(path);
    },
    [navigate],
  );

  const location = useLocation();
  const currentPath = location.pathname.split('/')[1];

  return (
    <div className="flex items-center justify-between h-full px-4 border-b bg-gray-50">
      <div className="flex items-center gap-4">
        {!hideCollapseMenuButton && (
          <button
            type="button"
            onClick={() => dispatch(setIsNavBarExpanded(!isNavBarExpanded))}
            className={`text-primary-dark ${isNavBarExpanded ? 'text-primary-darker' : ''}`}
          >
            <IconMenu2 />
          </button>
        )}
        <img
          src="/logo-header.png"
          alt="logo"
          className="w-[165px] h-[48px] text-center"
        />
      </div>
      <div className="flex items-center gap-4">
        {(userData?.userType === 'EMPLOYEE' || showOutletDropdown) && (
          <div className="w-[300px]">
            <ATMSelect
              value={outlet}
              onChange={(newValue) => dispatch(setOutlet(newValue))}
              options={outlets}
              valueAccessKey="_id"
              getOptionLabel={(option) => option?.name}
              placeholder="Please Select Type"
              isClearable={false}
            />
          </div>
        )}
        <div className="relative" onClick={(e) => togglePortal(e)}>
          <div className="size-[30px] bg-primary flex justify-center items-center text-white font-medium rounded-full text-xs capitalize cursor-pointer">
            {userData?.name?.[0]}
          </div>

          {dropdownOpen &&
            createPortal(
              <div
                ref={buttonRef}
                style={{
                  top: dropdownPosition.top,
                  right: dropdownPosition.right,
                }}
                className="absolute bg-white border border-gray-200 rounded-md w-[250px] z-[100000]"
              >
                <div className="flex items-center gap-2 p-2 capitalize bg-gray-100">
                  <div className="size-[25px] bg-primary flex justify-center items-center text-white font-medium rounded text-xs capitalize">
                    {userData?.name?.[0]}
                  </div>
                  <div>
                    <div className="text-sm font-semibold text-slate-700">
                      {userData?.name}
                    </div>
                    <div className="text-[0.60rem] text-slate-600 font-medium">
                      {userData?.email}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col">
                  <div
                    onClick={() => {
                      navigate('/user-profile/?tab=details');
                    }}
                    className={`flex items-center gap-2 p-2 text-xs font-medium border-t cursor-pointer  hover:font-semibold border-b  ${currentPath === 'user-profile' ? 'text-primary-50 font-semibold' : 'text-slate-600'}`}
                  >
                    <IconUserPause className="size-4" />
                    My Profile
                  </div>
                  <div
                    className="flex items-center gap-2 p-2 text-xs font-medium cursor-pointer text-slate-600"
                    onClick={() => setIsOpenChangePassword(true)}
                  >
                    <IconKey className="size-4" /> Change Password
                  </div>
                  <div
                    onClick={handleSignOut}
                    className="flex items-center gap-2 p-2 text-xs font-medium border-t cursor-pointer text-slate-600 hover:font-semibold"
                  >
                    <IconLogout className="size-4" /> Sign Out
                  </div>
                </div>
              </div>,
              document.body,
            )}
        </div>
      </div>
      {isOpenChangePasswordDialog && (
        <ChangePasswordFormLayoutWrapper
          onClose={() => setIsOpenChangePassword(false)}
        />
      )}
    </div>
  );
};

export default React.memo(ATMAppHeader);
