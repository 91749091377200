import Dashboard from './Dashboard';

type Props = {};

const DashboardWrapper = (props: Props) => {
  return (
    <>
      <Dashboard />
    </>
  );
};

export default DashboardWrapper;
